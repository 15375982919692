import styled from "styled-components"
import media from "../../theme/breakpoints"

export const Posts = styled.div`
  display: grid;
  grid-gap: 2.8rem;
  grid-template-columns: repeat(2, 1fr);

  ${media.lg`
    grid-gap: 2rem;
  `}

  ${media.sm`
    grid-template-columns: repeat(1, 1fr);
  `}
`

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 5rem;
`
