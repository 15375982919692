import React from "react"
import { Container, Separator } from "./style"

const Meta = ({ date, timeToRead, className }) => {
  return (
    <Container className={className}>
      {date}
      <Separator>{" · "}</Separator>
      <p>{timeToRead} min read</p>
    </Container>
  )
}

export default Meta
