import styled from "styled-components"
import { Link } from "gatsby"
import CategoryTag from "../CategoryTag"
import Meta from "../Meta"
import media from "../../theme/breakpoints"

const handleCardImageSize = size => {
  switch (size) {
    case "large":
      return "auto%"
    case "medium":
      return "26rem"
    default:
      return "18rem"
  }
}

export const Category = styled(CategoryTag)`
  margin: 0.5rem;
`

export const Card = styled.div`
  grid-column: ${props => (props.size === "large" ? "1/4" : "auto")};
  border: 1px solid ${props => props.theme.colors.border};

  ${media.lg`
    grid-column: ${props => (props.size === "large" ? "1/3" : "auto")};
  `}

  ${media.sm`
    grid-column: ${props => (props.size === "large" ? "1/2" : "auto")};
  `}
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.font.main};
  height: 100%;
  display: flex;
  flex-direction: ${props => (props.size === "large" ? "row" : "column")};
  border-radius: 2px;
  overflow: hidden;
  transition: 0.15s color ease, 0.2s box-shadow ease;
  box-shadow: 0 0 1px 0 transparent;

  ${media.sm`
    flex-direction: column;
  `}

  &:hover {
    box-shadow: 0 0 2px 0 ${props => props.theme.colors.font.main};
  }
`

export const PostTitle = styled.h4`
  font-size: ${props => (props.size === "large" ? 3.6 : 2.4)}rem;
  line-height: 1.2;
`

export const Excerpt = styled.p`
  text-decoration: none;
  color: ${props => props.theme.colors.font.main};
  font-family: ${props => props.theme.fontFamily.text};
  line-height: 1.3;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.size === "large" ? "2.4" : "2")}rem 2.4rem;
  box-sizing: border-box;
  flex: 1;
  font-size: 1.8rem;

  ${media.md`
    padding: 2rem;
  `}
`

export const ImageContainer = styled.div`
  flex-basis: ${props => (props.size === "large" ? "40%" : "auto")};
  max-height: ${({ size }) => handleCardImageSize(size)};
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }
`
export const Categories = styled.div`
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  margin: auto -0.5rem 0;
`

export const StyledMeta = styled(Meta)`
  margin-top: 0.8rem;
`
