const getCategories = (allPosts, allCategories) => {
  const postData = allPosts.map(post => {
    const postCategories = post.node.frontmatter.categories.map(postCat => {
      const category = allCategories.filter(
        allCat => allCat.node.frontmatter.category_name === postCat
      )
      return { id: category[0].node.id, ...category[0].node.frontmatter }
    })

    return {
      ...post.node,
      ...post.node.frontmatter,
      frontmatter: undefined,
      categories: postCategories
    }
  })

  return postData
}

export default getCategories
