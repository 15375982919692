import React from "react"
import Image from "gatsby-image"

import {
  Card,
  Excerpt,
  PostTitle,
  StyledLink,
  Content,
  ImageContainer,
  Categories,
  Category,
  StyledMeta
} from "./style"
import { useIsMd } from "../../hooks/utils"

const PostCard = ({ data, size }) => {
  const { path, title, date, image, categories, excerpt, timeToRead } = data
  const isMd = useIsMd()

  const cardSize = isMd ? "small" : size

  return (
    <Card size={cardSize}>
      <StyledLink to={`/${path}/`} size={cardSize}>
        {image && (
          <ImageContainer size={cardSize}>
            <Image fluid={image.childImageSharp.fluid} />
          </ImageContainer>
        )}
        <Content size={cardSize}>
          <PostTitle size={cardSize}>{title}</PostTitle>
          <Excerpt>{excerpt}</Excerpt>
          <Categories>
            {categories
              .sort((a, b) => a.category_name.localeCompare(b.category_name))
              .map((category, i) => (
                <Category
                  key={i}
                  to={`/category/${category.slug}`}
                  size="small"
                >
                  {category.category_name}
                </Category>
              ))}
          </Categories>
          <StyledMeta date={date} timeToRead={timeToRead} />
        </Content>
      </StyledLink>
    </Card>
  )
}

export default PostCard
