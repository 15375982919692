import React from "react"
import { graphql } from "gatsby"
import { useAllCategories } from "../../hooks/useAllCategories"
import Layout from "../../components/Layout"
import PostCard from "../../components/PostCard"
import Divider from "../../components/Divider"

import getCategories from "../../util/getCategories"

import { Title, Posts } from "./style"

const CategoryPageTemplate = ({ data, location, pageContext }) => {
  const blogPosts = data.allMdx.edges

  const allCategories = useAllCategories()
  const postData = getCategories(blogPosts, allCategories)

  return (
    <Layout
      location={location}
      type="main"
      seoTitle={pageContext.categoryName}
      description={data.site.siteMetadata.description}
      tagline={data.site.siteMetadata.tagline}
    >
      <Title>{pageContext.categoryName}</Title>
      {postData.length ? (
        <Posts>
          {postData.map(post => (
            <PostCard size="medium" key={post.id} data={post} />
          ))}
        </Posts>
      ) : (
        <p style={{ textAlign: "center" }}>Coming soon!</p>
      )}
      <Divider />
    </Layout>
  )
}

export default CategoryPageTemplate

export const pageQuery = graphql`
  query GetCategoryByID($categoryName: String!) {
    site {
      siteMetadata {
        description
        tagline
      }
    }
    allMdx(
      filter: { frontmatter: { categories: { eq: $categoryName } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields {
            filename
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            categories
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`
