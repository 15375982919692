import styled from "styled-components"
import media from "../../theme/breakpoints"

export const Container = styled.div`
  display: flex;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.font.secondary};

  ${media.md`
    flex-direction: column;
  `}

  p {
    margin: 0;
  }
`

export const Separator = styled.span`
  margin: 0 0.5rem;
  font-weight: bold;

  ${media.md`
    display: none;
  `}
`
